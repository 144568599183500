import _ from "lodash";
import { arrangeOrders } from "./arrangeOrders";

let dragItemIndex = null;
let dragParentTopicIndex = null;
export function drag(event, itemIndex) {
    dragItemIndex = itemIndex;

    event.dataTransfer.setData("text/uri-list", event.target.id);
}

export function allowDrop(event) {
    event.preventDefault();
}

export function drop(
    event,
    dropItemIndex,
    templateMeeting,
) {
    event.preventDefault();
    let parentId = dragParentTopicIndex;
    let dropItemTopicParentId = null;
    // NOTE: here templateMeeting and setTemplateMeeting is used for both templates and meetings
    // when called from createTemplate or editTemplate the variables will receive template and setTemplate states
    // whereas, when called from createMeeting or editMeeting the variables will receive meeting and setMeeting states
    let localMeeting = _.cloneDeep(templateMeeting);
    if((dragItemIndex === dropItemIndex) || (dragParentTopicIndex === dragItemIndex)) return localMeeting;
    dropItemTopicParentId = localMeeting.items[dropItemIndex].topic_parent_id;
    const dragItem = localMeeting.items[dragItemIndex];
    let childIndex = dragItemIndex+1;
    const children = [];
    const childIdToCompare = dragItem.id === null || dragItem.id.includes('fake-uuid') ? dragItemIndex : dragItem.id;
    while (childIndex < localMeeting.items.length && (localMeeting.items[childIndex].topic_parent_id == childIdToCompare || localMeeting.items[childIndex].topic_parent_id == dragItemIndex)){
        children.push(localMeeting.items[childIndex]);
        childIndex++;
    }
    localMeeting.items.splice(dragItemIndex, 1 + children.length);

    if(dragParentTopicIndex !== null){
        const dragIndex = dragParentTopicIndex > dragItemIndex ? dragParentTopicIndex - 1 : dragParentTopicIndex;
        if (localMeeting.items[dragIndex].id !== null && !localMeeting.items[dragIndex].id.includes('fake-uuid')){
            parentId = localMeeting.items[dragIndex].id;
        }
        dragItem.topic_parent_id = parentId;
        if(dragItemIndex < dropItemIndex){
            localMeeting.items.splice(dropItemIndex, 0, dragItem);
        }else{
            localMeeting.items.splice(dropItemIndex + 1, 0, dragItem);
        }
        dragParentTopicIndex = null;
    }else{
        dragItem.topic_parent_id = dropItemTopicParentId;
        localMeeting.items.splice(dropItemIndex, 0, dragItem);
    }
    while(children.length > 0){
        const child = children.pop();
        localMeeting.items.splice(dropItemIndex+1,0,child);
    }
    return localMeeting;
}

export function dragEnterTopic(itemIndex){
    dragParentTopicIndex = itemIndex;
}

export function dragExitTopic(){
    dragParentTopicIndex = null;
}