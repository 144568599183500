import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import {
    DEFAULT_MEETING_ITEMS,
    FAKE_UUID_IDENTIFIER,
    GET_ALL_GROUPS_URL,
    GET_ALL_TEMPLATES_URL,
    GET_TEMPLATE_BY_ID_URL,
    LOGIN_ROUTE,
    SUN_EDITOR_BUTTONS_LIST,
    SUN_EDITOR_DEFAULT_STYLE,
} from "../../constants";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/createMeeting.css";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import _ from "lodash";
import { arrangeOrders } from "../utils/arrangeOrders";
import { allowDrop, drag, dragEnterTopic, dragExitTopic, drop } from "../utils/dragNdrop";
import { getGroupByName } from "../utils/getGroupByNameOrId";
import handleCreateMeeting from "../utils/handleCreateMeeting";
import handleRemoveFileFromFilesToBeUploaded from "../utils/handleRemoveFileFromFilesToBeUploaded";
import FileDescriptionModal from "../widgets/fileDescriptionModal";
import AddItemsPopup from "../widgets/addItemsPopup";
import { v4 as uuidv4 } from "uuid";
import { capitalize } from "../utils/stringUtils";

const CreateMeeting = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const dragRef = useRef([]);
    const hoverRef = useRef([]);
    const [dragParentTopicIndex, setDragParentTopicIndex] = useState(null);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupIndex, setShowPopupIndex] = useState(null);
    const [isPopupPosUp, setIsPopupPosUp] = useState(true); // is the popup position on the top of the item or not (this is only used for the last item)
    const [readOnly, setReadOnly] = useState(false);
    const [showTopicDescriptionLabel, setShowTopicDescriptionLabel] =
        useState(false);
    const [topicDescriptionLabelIndex, setTopicDescriptionLabelIndex] =
        useState(null);

    const [meetingSettings, setMeetingSettings] = useState({
        show_name_in_packet: true,
        show_title_in_packet: true,
        show_date_in_packet: true
    });

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(null);
    const [date, setDate] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [topicNumbering, setTopicNumbering] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [endTimeError, setEndTimeError] = useState(null);
    const [createMeetingError, setCreateMeetingError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const LOCAL_NAME = "Name";
    const LOCAL_NAME_PACKET = "Show Name On Packet";
    const LOCAL_TITLE = "Title";
    const LOCAL_TITLE_PACKET = "Show Title On Packet";
    const LOCAL_DATE = "Date";
    const LOCAL_DATE_PACKET = "Show Date On Packet";
    const LOCAL_START_TIME = "Start time";
    const LOCAL_END_TIME = "End time";
    const LOCAL_GROUP = "Group";
    const LOCAL_TEMPLATE = "Template";
    const LOCAL_TOPIC_NUMBERING = "Topic Numbering";

    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({});
    const [templates, setTemplates] = useState([{ id: -1, name: "None" }]);
    const [template, setTemplate] = useState({ id: -1, name: "None" });
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [showFileDescriptionModal, setShowFileDescriptionModal] =
        useState(false);
    const [selectedFileEvent, setSelectedFileEvent] = useState(null);
    const [selectedFileIsMinuteFile, setSelectedFileIsMinuteFile] =
        useState(false);
    const [selectedFileTopicIndex, setSelectedFileTopicIndex] = useState(null);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const toggleSettings = () => setSettingsOpen(!settingsOpen) 

    const [meeting, setMeeting] = useState({
        meetingSettings: {
            show_name_in_packet: true,
            show_title_in_packet: true,
            show_date_in_packet: true
        },
        name: null,
        title: null,
        date: null,
        topic_numbering: null,
        start_time: null,
        end_time: null,
        items: DEFAULT_MEETING_ITEMS,
    });

    const fetchGroups = async () => {
        const response = await axios(GET_ALL_GROUPS_URL + `?per_page=50`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setGroup(response.data.groups.data[0]);
        setGroups(response.data.groups.data);
    };

    const fetchTemplates = async () => {
        const response = await axios(GET_ALL_TEMPLATES_URL + `?per_page=50`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setTemplates(templates.concat(response.data.templates.data));
    };

    const fetchTemplateById = async (id) => {
        const response = await axios.get(GET_TEMPLATE_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        let localMeeting = _.cloneDeep(meeting);
        let templateItems = response.data.template.items;
        if (templateItems)
            templateItems.forEach((templateItem) => {
                if (templateItem.type === "TOPIC") {
                    templateItem["minute"] = null;
                    if (!("is_private_file" in templateItem))
                        templateItem["is_private_file"] = false;
                    if (!("is_private_topic" in templateItem))
                        templateItem["is_private_topic"] = false;
                }
                delete templateItem["template_id"];
                templateItem.id = null;
                delete templateItem["created_at"];
                delete templateItem["updated_at"];
            });

        localMeeting["items"] = templateItems;
        setMeeting(localMeeting);
    };

    useEffect(() => {
        fetchGroups();
        fetchTemplates();
    }, []);

    const handleAddItem = (e, type, addIndex, addPos) => {
        e.preventDefault();

        if (addPos === "BOTTOM") ++addIndex;

        let localMeeting = _.cloneDeep(meeting);
        const itemsLength = localMeeting.items.length;
        if (type === "AGENDA") {
            localMeeting.items.splice(addIndex, 0, {
                id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                topic_parent_id: null,
                type: "AGENDA",
            });
        } else if (type === "TOPIC") {
            localMeeting.items.splice(addIndex, 0, {
                id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
                name: null,
                description: null,
                order: itemsLength + 1,
                minute: null,
                is_active: 1,
                is_private_file: false,
                is_private_topic: false,
                topic_parent_id: null,
                type: "TOPIC",
            });
        }

        arrangeOrders([...localMeeting.items], "MEETING");
        setMeeting(localMeeting);
    };

    const handleCopy = (e, itemIndex, item) => {
        e.preventDefault();

        item.id = uuidv4();
        item.order = item.order + 1;
        let localMeeting = _.cloneDeep(meeting);
        localMeeting.items.splice(itemIndex + 1, 0, item);
        arrangeOrders([...localMeeting.items], "MEETING");
        setMeeting(localMeeting);
    };

    const handleDelete = (e, itemIndex) => {
        e.preventDefault();

        let localMeeting = _.cloneDeep(meeting);
        localMeeting.items = localMeeting.items.map((item) => {
            console.log(`Item Parent Id: ${item.topic_parent_id}`)
            if ((item.topic_parent_id === localMeeting.items[itemIndex].id) || item.topic_parent_id == itemIndex){
                item.topic_parent_id = null;
            }
            return item;
        })    
        localMeeting.items = localMeeting.items.filter(
            (item, index) => index !== itemIndex
        );
        setMeeting(localMeeting);
    };

    const handleEditorChange = (content, itemIndex) => {
        // here we are using a closure inside setMeeting()
        // otherwise inside the SunEditor component we were receiving older value of the meeting state
        // whereas, using closure gives us the current value
        setMeeting((meeting) => {
            meeting.items[itemIndex].description = content;
            return meeting;
        });
    };

    const getTemplateByName = (name) => {
        return templates.find(function (templateElement) {
            return templateElement.name === name;
        });
    };

    async function handleInputChange(e, type) {
        // these three checks are for settings checkboxes
        if (type === LOCAL_NAME_PACKET) setMeetingSettings({...meetingSettings, show_name_in_packet: !meetingSettings.show_name_in_packet})
        else if (type === LOCAL_TITLE_PACKET) setMeetingSettings({...meetingSettings, show_title_in_packet: !meetingSettings.show_title_in_packet})
        else if (type === LOCAL_DATE_PACKET) setMeetingSettings({...meetingSettings, show_date_in_packet: !meetingSettings.show_date_in_packet})
        else{
            // don't want preventDefault happening when checkboxes are checked
            e.preventDefault();
        }

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
        if (type === LOCAL_TITLE) {
            e.target.value = capitalize(e.target.value);
            setTitle(e.target.value);
        }
        if (type === LOCAL_DATE) setDate(e.target.value);
        if (type === LOCAL_TOPIC_NUMBERING) setTopicNumbering(e.target.value);
        if (type === LOCAL_START_TIME) setStartTime(e.target.value);
        if (type === LOCAL_END_TIME) setEndTime(e.target.value);
        if (type === LOCAL_GROUP) {
            const matchedGroup = getGroupByName(e.target.value, groups);

            setGroup({
                name: matchedGroup["name"],
                id: matchedGroup["id"],
            });
        }
        if (type === LOCAL_TEMPLATE) {
            const matchedTemplate = getTemplateByName(e.target.value);

            setTemplate({
                name: matchedTemplate["name"],
                id: matchedTemplate["id"],
            });
            if (matchedTemplate["id"] !== -1)
                await fetchTemplateById(matchedTemplate["id"]);
        }
    }

    function handleTopicChange(e, itemIndex, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        let localMeeting = _.cloneDeep(meeting);
        if (type === "TOPIC NAME")
            localMeeting.items[itemIndex].name = e.target.value;
        else if (type === "TOPIC DESCRIPTION")
            localMeeting.items[itemIndex].description = e.target.value;
        else if (type === "TOPIC IS PRIVATE")
            localMeeting.items[itemIndex].is_private_topic =
                !meeting.items[itemIndex].is_private_topic;
        else if (type === "TOPIC FILE IS PRIVATE")
            localMeeting.items[itemIndex].is_private_file =
                !meeting.items[itemIndex].is_private_file;
        else if (type === "CHILD NUMBERING")
            localMeeting.items[itemIndex].child_numbering = e.target.value;

        setMeeting(localMeeting);
    }

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setTitleError(null);
        setDateError(null);
        setStartTimeError(null);
        setEndTimeError(null);
        setCreateMeetingError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }
        if (!validateEmptyField(title)) {
            setTitleError("Please fill this field!");
            return false;
        }
        if (!validateEmptyField(date)) {
            setDateError("Please fill this field!");
            return false;
        }
        if (!validateEmptyField(startTime)) {
            setStartTimeError("Please fill this field!");
            return false;
        } else {
            const startTimeSelected = new Date(
                `${date} ${startTime}`
            ).getTime();
            if (startTimeSelected <= new Date().getTime()) {
                setStartTimeError(
                    "Please make sure that Start time is after the Current time!"
                );
                return false;
            }
        }

        if (!validateEmptyField(endTime)) {
            setEndTimeError("Please fill this field!");
            return false;
        } else {
            if (startTime >= endTime) {
                setEndTimeError(
                    "Please make sure that End time is after the Start time!"
                );
                return false;
            }
        }

        for (let i = 0; i < meeting.items.length; i++) {
            if (
                meeting.items[i].type === "TOPIC" &&
                !validateEmptyField(meeting.items[i].name)
            ) {
                setCreateMeetingError("Please fill all the item name fields");
                return false;
            }
            if (
                meeting.items[i].type === "AGENDA" &&
                !validateEmptyField(meeting.items[i].description)
            ) {
                setCreateMeetingError(
                    "Please fill all the text section fields"
                );
                return false;
            }
        }

        return true;
    }

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        {showFileDescriptionModal ? (
                            <FileDescriptionModal
                                setShowFileDescriptionModal={
                                    setShowFileDescriptionModal
                                }
                                fileEvent={selectedFileEvent}
                                topicIndex={selectedFileTopicIndex}
                                topic={meeting.items[selectedFileTopicIndex]}
                                isMinuteFile={selectedFileIsMinuteFile}
                                meeting={meeting}
                                setMeeting={setMeeting}
                                minuteFilesToBeUploaded={null}
                                setMinuteFilesToBeUploaded={null}
                                filesToBeUploaded={filesToBeUploaded}
                                setFilesToBeUploaded={setFilesToBeUploaded}
                            />
                        ) : null}
                        <div className="page-header">
                            <h1 className="d-inline">Create Meeting</h1>
                        </div>
                        <hr />
                        <Form
                            onSubmit={(e) =>
                                handleCreateMeeting(
                                    e,
                                    jwt,
                                    clearSuccessAndErrorMessages,
                                    validateAll,
                                    history,
                                    meetingSettings,
                                    name,
                                    title,
                                    date,
                                    topicNumbering,
                                    group,
                                    startTime,
                                    endTime,
                                    meeting,
                                    filesToBeUploaded,
                                    setSuccessMessage,
                                    setCreateMeetingError,
                                    setNameError
                                )
                            }
                        >
                            <Button
                                color="primary"
                                type="submit"
                                className="float-end"
                            >
                                Save Meeting
                            </Button>
                            <Row>
                                <Col>
                                    <Button type="button" color="link" onClick={toggleSettings}>
                                        <FontAwesomeIcon
                                            icon={faCog}
                                            color="grey"
                                            size="2x"
                                            title="Show meeting settings"
                                        />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Collapse isOpen={settingsOpen}>
                                    <Card>
                                        <CardBody>
                                            <h5>Set meeting fields to show in Agenda Packet:</h5>
                                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_NAME_PACKET)} checked={meetingSettings.show_name_in_packet}/> Show Name in Agenda Packet</label>
                                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_TITLE_PACKET)} checked={meetingSettings.show_title_in_packet}/> Show Title in Agenda Packet</label>
                                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_DATE_PACKET)} checked={meetingSettings.show_date_in_packet}/> Show Date/Time in Agenda Packet</label>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        label={LOCAL_NAME}
                                        placeholder={`Enter ${LOCAL_NAME}`}
                                        type="text"
                                        autofocus={true}
                                        value={name ?? ""}
                                        onChange={(e) =>
                                            handleInputChange(e, LOCAL_NAME)
                                        }
                                    />
                                    {nameError !== null ? (
                                        <p className="text-danger">
                                            {nameError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <TextField
                                        label={LOCAL_TITLE}
                                        placeholder={`Enter ${LOCAL_TITLE}`}
                                        type="text"
                                        value={title ?? ""}
                                        onChange={(e) =>
                                            handleInputChange(e, LOCAL_TITLE)
                                        }
                                    />
                                    {titleError !== null ? (
                                        <p className="text-danger">
                                            {titleError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_TOPIC_NUMBERING}
                                            name={LOCAL_TOPIC_NUMBERING}
                                            type="select"
                                            value={topicNumbering ?? "None"}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_TOPIC_NUMBERING
                                                )
                                            }
                                        >
                                            {["None", "A", "1", "I"].map((element) => {
                                                return (
                                                    <option key={element}>{element}</option>
                                                );
                                            })}
                                        </Input>
                                        <Label htmlFor={LOCAL_TOPIC_NUMBERING}>
                                            {LOCAL_TOPIC_NUMBERING}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_DATE}
                                            name={LOCAL_DATE}
                                            placeholder="date placeholder"
                                            type="date"
                                            value={date ?? ""}
                                            onChange={(e) =>
                                                handleInputChange(e, LOCAL_DATE)
                                            }
                                        />
                                        <Label>{LOCAL_DATE}</Label>
                                    </FormGroup>
                                    {dateError !== null ? (
                                        <p className="text-danger">
                                            {dateError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_START_TIME}
                                            name={LOCAL_END_TIME}
                                            type="time"
                                            value={startTime ?? ""}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_START_TIME
                                                )
                                            }
                                        />
                                        <Label>{LOCAL_START_TIME}</Label>
                                    </FormGroup>
                                    {startTimeError !== null ? (
                                        <p className="text-danger">
                                            {startTimeError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_END_TIME}
                                            name={LOCAL_END_TIME}
                                            type="time"
                                            value={endTime ?? ""}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_END_TIME
                                                )
                                            }
                                        />
                                        <Label>{LOCAL_END_TIME}</Label>
                                    </FormGroup>
                                    {endTimeError !== null ? (
                                        <p className="text-danger">
                                            {endTimeError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_GROUP}
                                            name={LOCAL_GROUP}
                                            type="select"
                                            className="mb-2"
                                            value={group.name ?? ""}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_GROUP
                                                )
                                            }
                                        >
                                            {groups.map(
                                                (groupElement, index) => {
                                                    return (
                                                        <option
                                                            key={
                                                                groupElement.id
                                                            }
                                                        >
                                                            {groupElement.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Input>
                                        <Label htmlFor={LOCAL_GROUP}>
                                            {LOCAL_GROUP}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_TEMPLATE}
                                            name={LOCAL_TEMPLATE}
                                            type="select"
                                            className="mb-2"
                                            value={template.name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_TEMPLATE
                                                )
                                            }
                                        >
                                            {templates.map(
                                                (templateElement, index) => {
                                                    return (
                                                        <option
                                                            key={
                                                                templateElement.id
                                                            }
                                                        >
                                                            {
                                                                templateElement.name
                                                            }
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Input>
                                        <Label htmlFor={LOCAL_TEMPLATE}>
                                            {LOCAL_TEMPLATE}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* agendas */}
                            <div className="mb-2">
                                <AddItemsPopup
                                    handleAddItem={handleAddItem}
                                    addIndex={meeting.items.length}
                                />
                                {meeting.items?.map((item, itemIndex) => {
                                    return (
                                        <div
                                        className={`item ${'topic_parent_id' in item && item.topic_parent_id !== null ? 'child-item' : ''}`}
                                        id={`itemId-${item.id !== null ? item.id : itemIndex}`}
                                            key={item.id}
                                            ref={(el) =>
                                            (hoverRef.current[itemIndex] =
                                                el)
                                            }
                                            onMouseOver={(e) => {
                                                e.preventDefault();

                                                const mouseY = e.clientY;
                                                const elementY =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect().y;
                                                const elementHeight =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect()
                                                        .height;

                                                setShowPopup(true);
                                                if (
                                                    mouseY - elementY >
                                                    elementHeight / 2
                                                ) {
                                                    if (itemIndex === meeting.items.length - 1) {
                                                        setIsPopupPosUp(false);
                                                        setShowPopupIndex(itemIndex);
                                                    }
                                                    else
                                                        setShowPopupIndex(itemIndex + 1);
                                                }
                                                else {
                                                    if (itemIndex === meeting.items.length - 1)
                                                        setIsPopupPosUp(true);
                                                    setShowPopupIndex(itemIndex);
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                e.preventDefault();
                                                setShowPopup(false);
                                                setShowPopupIndex(null);
                                            }}
                                        >
                                            {itemIndex > 0 && ('topic_parent_id' in item === false || item.topic_parent_id === null) ? <hr/> : null}
                                            <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup
                                                && itemIndex === showPopupIndex
                                                && !(itemIndex === meeting.items.length - 1 && !isPopupPosUp) // This part is only to hide this popup for the last item when the bottom popup of the last item is visible
                                                ? "visible" : "invisible"}`}>
                                                <AddItemsPopup
                                                    handleAddItem={
                                                        handleAddItem
                                                    }
                                                    addIndex={itemIndex}
                                                    addPos="TOP"
                                                    buttonSize="sm"
                                                />
                                            </div>
                                            <div
                                                className="agenda-wrapper border p-2 mt-3"
                                                key={`${item.type} ${item.order}`}
                                                draggable={false}
                                                ref={(el) =>
                                                (dragRef.current[
                                                    itemIndex
                                                ] = el)
                                                }
                                                onDragStart={(event) => {
                                                    setReadOnly(true);
                                                    if(itemIndex + 1 >= meeting.items.length || (meeting.items[itemIndex + 1].topic_parent_id === null) || (meeting.items[itemIndex+1].topic_parent_id !== item.id && meeting.items[itemIndex+1].topic_parent_id != itemIndex)){
                                                        setDragParentTopicIndex(itemIndex);
                                                    }
                                                    drag(event, itemIndex);
                                                }}
                                                onDragEnd={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false);
                                                }}
                                                onDrop={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false); // this is repeated here even after onDragEnd() because it was observed that sometimes only onDrop() is called when the drag ends
                                                    const localMeeting = drop(
                                                        event,
                                                        itemIndex,
                                                        meeting,
                                                        setMeeting,
                                                        "MEETING"
                                                    );
                                                    arrangeOrders([...localMeeting.items], 'MEETING');
                                                    setMeeting(localMeeting);
                                                }}
                                                onDragOver={(event) =>
                                                    allowDrop(event)
                                                }
                                            >
                                                <div
                                                    className="drag-wrapper bg-muted"
                                                    // using mouse enter and leave events to set draggable property as true or false
                                                    // otherwise even when someone wants to just select some item text(instead of dragging the item)
                                                    // the item was dragged and this was undesirable
                                                    onMouseEnter={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = true;
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = false;
                                                    }}
                                                >
                                                    <div className="handle">
                                                        ...
                                                    </div>
                                                    <Button
                                                        color="danger float-end"
                                                        onClick={(e) =>
                                                            handleDelete(
                                                                e,
                                                                itemIndex
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        color="warning float-end"
                                                        onClick={(e) =>
                                                            handleCopy(
                                                                e,
                                                                itemIndex,
                                                                // cloneDeep is used here so that any changes made to the item in handleCopy() does not effect the existing item
                                                                _.cloneDeep(
                                                                    item
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Copy
                                                    </Button>
                                                </div>
                                                {item.type === "AGENDA" ? (
                                                    /* agenda */
                                                    <div>
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    /* topic */
                                                    <div>
                                                        <div className="d-flex">
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 me-3 flex-grow-1"
                                                            >
                                                                <Input
                                                                    placeholder="Enter topic name"
                                                                    className="topic-name w-100"
                                                                    name={`Topic Name ${itemIndex}`}
                                                                    id={`Topic Name ${itemIndex}`}
                                                                    type="text"
                                                                    value={
                                                                        item.name ??
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            "TOPIC NAME"
                                                                        )
                                                                    }
                                                                />
                                                                <Label
                                                                    htmlFor={`Topic Name ${itemIndex}`}
                                                                >
                                                                    Topic Name
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 me-3 flex-grow-1"
                                                            >
                                                                <Input
                                                                    id={`Topic isPrivateTopic ${itemIndex}`}
                                                                    name={`Topic isPrivateTopic ${itemIndex}`}
                                                                    type="select"
                                                                    className="mb-2"
                                                                    value={
                                                                        item.is_private_topic
                                                                            ? "YES"
                                                                            : "NO"
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            `TOPIC IS PRIVATE`
                                                                        )
                                                                    }
                                                                >
                                                                    {[
                                                                        "YES",
                                                                        "NO",
                                                                    ].map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Input>
                                                                <Label
                                                                    htmlFor={`Topic isPrivate ${itemIndex}`}
                                                                >
                                                                    Private Topic
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 me-3 flex-grow-1"
                                                            >
                                                                <Input
                                                                    id={`Topic isPrivateFiles ${itemIndex}`}
                                                                    name={`Topic isPrivateFiles ${itemIndex}`}
                                                                    type="select"
                                                                    className="mb-2"
                                                                    value={
                                                                        item.is_private_file
                                                                            ? "YES"
                                                                            : "NO"
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            `TOPIC FILE IS PRIVATE`
                                                                        )
                                                                    }
                                                                >
                                                                    {[
                                                                        "YES",
                                                                        "NO",
                                                                    ].map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Input>
                                                                <Label
                                                                    htmlFor={`Topic isPrivateFiles ${itemIndex}`}
                                                                >
                                                                    Private Files
                                                                </Label>
                                                            </FormGroup>
                                                            {itemIndex + 1 < meeting.items.length && meeting.items[itemIndex + 1].topic_parent_id !== null ? 
                                                                <FormGroup className='mt-3 me-3 flex-grow-1' floating>
                                                                <Input
                                                                    id={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}
                                                                    name={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}
                                                                    type="select"
                                                                    className="mb-2"
                                                                    value={item.child_numbering ?? "None"}
                                                                    onChange={(e) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            'CHILD NUMBERING'
                                                                        )
                                                                    }
                                                                >
                                                                    {["None", "A", "1", "I"].map((element) => {
                                                                        return (
                                                                            <option key={element}>{element}</option>
                                                                        );
                                                                    })}
                                                                </Input>
                                                                <Label htmlFor={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}>
                                                                    Sub-Item Numbering
                                                                </Label>
                                                                </FormGroup>
                                                                : null
                                                                }
                                                        </div>

                                                        {showTopicDescriptionLabel &&
                                                            topicDescriptionLabelIndex ===
                                                            itemIndex ? (
                                                            <Label
                                                                className="topic-description-label"
                                                                htmlFor={`Topic Description ${itemIndex}`}
                                                            >
                                                                Topic
                                                                Description
                                                            </Label>
                                                        ) : null}
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onFocus={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    itemIndex
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    null
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    false
                                                                );
                                                            }}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />

                                                        <Row className="mt-2 mb-2 select-files">
                                                            <Col>
                                                                <input
                                                                    id={`invisible-file-input ${itemIndex}`}
                                                                    type="file"
                                                                    className="d-none"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedFileEvent(
                                                                            e
                                                                        );
                                                                        setSelectedFileIsMinuteFile(
                                                                            false
                                                                        );
                                                                        setSelectedFileTopicIndex(
                                                                            itemIndex
                                                                        );
                                                                        setShowFileDescriptionModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                                <Label
                                                                    htmlFor={`invisible-file-input ${itemIndex}`}
                                                                >
                                                                    <p className="btn btn-outline-primary">
                                                                        Upload
                                                                    </p>
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        {"filesToBeUploaded" in
                                                            item ? (
                                                            <Row className="mt-2 mb-2 select-files">
                                                                <Label>
                                                                    Files to be
                                                                    uploaded
                                                                </Label>
                                                                {"filesToBeUploaded" in
                                                                    item
                                                                    ? item.filesToBeUploaded.map(
                                                                        (
                                                                            file,
                                                                            fileIndex
                                                                        ) => {
                                                                            return (
                                                                                <Col
                                                                                    key={
                                                                                        file.file_name
                                                                                    }
                                                                                >
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            {
                                                                                                file.file_name
                                                                                            }
                                                                                            <Button
                                                                                                color="danger"
                                                                                                className="float-end"
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleRemoveFileFromFilesToBeUploaded(
                                                                                                        e,
                                                                                                        itemIndex,
                                                                                                        file,
                                                                                                        false,
                                                                                                        meeting,
                                                                                                        setMeeting,
                                                                                                        null,
                                                                                                        null,
                                                                                                        filesToBeUploaded,
                                                                                                        setFilesToBeUploaded
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Del
                                                                                            </Button>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )
                                                                    : null}
                                                            </Row>
                                                        ) : null}
                                                        {(dragParentTopicIndex !== null && dragParentTopicIndex !== itemIndex) && item.topic_parent_id === null ? 
                                                        <Row onDragEnter={() => dragEnterTopic(itemIndex)} onDragExit={dragExitTopic}>
                                                            <Col data-id={itemIndex} className='drag-here-parent'>
                                                                Drag Here To Create Child
                                                            </Col>
                                                        </Row>
                                                        : null    
                                                        }
                                                        <div className="children"></div>
                                                    </div>
                                                )}
                                            </div>
                                            {/* This item popup at the bottom will only appear if this is the last item */}
                                            {itemIndex === meeting.items.length - 1 ?
                                                <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup && !isPopupPosUp && itemIndex === showPopupIndex ? "visible" : "invisible"}`}>
                                                    <AddItemsPopup
                                                        handleAddItem={
                                                            handleAddItem
                                                        }
                                                        addIndex={itemIndex}
                                                        addPos="BOTTOM"
                                                        buttonSize="sm"
                                                    />
                                                </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            {createMeetingError !== null ? (
                                <p className="text-danger">
                                    {createMeetingError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button
                                color="primary"
                                type="submit"
                                className="mb-5"
                            >
                                Save Meeting
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateMeeting;
