import SunEditor from "suneditor-react";

export default function ViewAgenda({item}){
    return(
        <div
            key={`Agenda ${item.id}`}
            className={`view-suneditor ${item.topic_parent_id !== null ? 'child' : ''}`}
        >
            <SunEditor
                defaultValue={
                    item.description
                }
                setDefaultStyle="height: auto"
                setOptions={{
                    buttonList: [],
                }}
                disable={true}
            />
        </div>
    )
}