import SunEditor from "suneditor-react";
import { DOWNLOAD_MEETING_FILES_URL } from "../../constants";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ViewAgenda from "./viewAgenda";

export default function ViewTopic({item, meeting, is_minutes, itemIndex}){
    const children = [];
    let childIndex = itemIndex + 1;
    while (childIndex < meeting.items.length && (meeting.items[childIndex].topic_parent_id === item.id || meeting.items[childIndex].topic_parent_id == itemIndex)){
        children.push(meeting.items[childIndex]);
        childIndex++;
    }
    return(
        <div
            key={`Topic ${item.id}`}
            className={`view-suneditor ${item.topic_parent_id !== null ? 'child' : ''}`}
        >
            <span className="fw-bold">
                {item.name}
            </span>
            {item.description !== null ? (
                <SunEditor
                    defaultValue={
                        item.description
                    }
                    setDefaultStyle="height: auto"
                    setOptions={{
                        buttonList: [],
                    }}
                    disable={true}
                />
            ) : null}
            {item.minute && is_minutes ? (
                <div className="border p-2 w-100">
                    {item.minute}
                </div>
            ) : null}
            {item.files
                ? item.files.map((file) => {
                        return (
                            <a
                                key={file.id}
                                href={DOWNLOAD_MEETING_FILES_URL.replace(
                                    ":id",
                                    file.id
                                )}
                                className="d-block"
                            >
                                <Button className="border-0 bg-success mt-2 mb-2">
                                    <FontAwesomeIcon
                                        icon={
                                            faDownload
                                        }
                                        size="sm"
                                        title="File Download"
                                        className="me-2"
                                    />
                                    {
                                        file.description
                                    }
                                </Button>
                            </a>
                        );
                    })
                : null}
            {item.minute_files && is_minutes
                ? item.minute_files.map(
                        (file) => {
                            return (
                                <a
                                    key={
                                        file.id
                                    }
                                    href={DOWNLOAD_MEETING_FILES_URL.replace(
                                        ":id",
                                        file.id
                                    )}
                                >
                                    <Button className="border-0 bg-success mt-2 mb-2">
                                        <FontAwesomeIcon
                                            icon={
                                                faDownload
                                            }
                                            size="sm"
                                            title="File Download"
                                            className="me-2"
                                        />
                                        {
                                            file.description
                                        }
                                    </Button>
                                </a>
                            );
                        }
                    )
                : null}
                {children.length > 0 ? <ol type={item.child_numbering}>
                   
                    {children.map((child,childIndex) => {
                        if(child.type === 'TOPIC'){
                            return (
                                <li 
                                key={`${itemIndex}-${childIndex + 1}`}
                                className="li-marker-bold">
                                    <ViewTopic 
                                        item={child}
                                        itemIndex={childIndex}
                                        meeting={meeting}
                                        is_minutes={is_minutes}
                                    />
                                </li>
                            )
                        }else{
                            return (
                            <li 
                            key={`${itemIndex}-${childIndex + 1}`}
                            className="li-marker-bold">
                                <ViewAgenda 
                                    item={child} />
                            </li>
                            )
                        }
                    })}    
                
                </ol> : null}
                
        </div>
    )
}