const updateOrders = (meetingOrTemplate, items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList) => {
    const itemsToBeUpdated = [];
    for (let i = 0; i < items.length; i++) {
        if (items[i].id) {
            const newItem = items[i];
            const originalItemResult = getOriginalItem(meetingOrTemplate, newItem.id);
            const originalItem = originalItemResult.originalItem;
            const originalIndex = originalItemResult.originalIndex;
            if (newItem.order !== originalItem.order) {
                if (
                    !checkIfItemExistsInUpdateList(
                        update_items_id_type_list,
                        meetingOrTemplate,
                        originalIndex
                    )
                ) {
                    itemsToBeUpdated.push({id:originalItem.id, type:originalItem.type});
                }
            }
        }
    }
    if(itemsToBeUpdated.length > 0){
        setUpdateItemsIdTypeList([
            ...update_items_id_type_list,
            ...itemsToBeUpdated
        ])
    }

}

const getOriginalItem = (meetingOrTemplate, id) => {
    for (let index = 0; index < meetingOrTemplate.items.length; index++) {
        if (meetingOrTemplate.items[index].id === id)
            return { originalItem: meetingOrTemplate.items[index], originalIndex: index };
    }
}

export default updateOrders;