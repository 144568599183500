import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    CREATE_TEMPLATE_URL,
    DEFAULT_TEMPLATE_ITEMS,
    FAKE_UUID_IDENTIFIER,
    LOGIN_ROUTE,
    SUN_EDITOR_BUTTONS_LIST,
    SUN_EDITOR_DEFAULT_STYLE,
    TEMPLATES_ROUTE,
} from "../../constants";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/createTemplate.css";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { allowDrop, drag, dragEnterTopic, dragExitTopic, drop } from "../utils/dragNdrop";
import _ from "lodash";
import { arrangeOrders } from "../utils/arrangeOrders";
import { v4 as uuidv4 } from "uuid";
import { capitalize } from "../utils/stringUtils";
import AddItemsPopup from "../widgets/addItemsPopup";

const CreateTemplate = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const dragRef = useRef([]);
    const hoverRef = useRef([]);
    const [dragParentTopicIndex, setDragParentTopicIndex] = useState(null);

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [createTemplateError, setCreateTemplateError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const LOCAL_NAME = "Name";

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupIndex, setShowPopupIndex] = useState(null);
    const [isPopupPosUp, setIsPopupPosUp] = useState(true); // is the popup position on the top of the item or not (this is only used for the last item)
    const [readOnly, setReadOnly] = useState(false);
    const [showTopicDescriptionLabel, setShowTopicDescriptionLabel] =
        useState(false);
    const [topicDescriptionLabelIndex, setTopicDescriptionLabelIndex] =
        useState(null);

    const [template, setTemplate] = useState({
        name: null,
        items: DEFAULT_TEMPLATE_ITEMS,
    });

    const handleAddItem = (e, type, addIndex, addPos) => {
        e.preventDefault();

        if (addPos === "BOTTOM") ++addIndex;

        let localTemplate = _.cloneDeep(template);
        const itemsLength = localTemplate.items.length;
        if (type === "AGENDA") {
            localTemplate.items.splice(addIndex, 0, {
                // uuids are used here because these ids are used as keys to differentiate the items while rendering
                id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                topic_parent_id: null,
                type: "AGENDA",
            });
        } else if (type === "TOPIC") {
            localTemplate.items.splice(addIndex, 0, {
                id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
                name: null,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                topic_parent_id: null,
                type: "TOPIC",
            });
        }

        arrangeOrders([...localTemplate.items], "TEMPLATE");
        setTemplate(localTemplate);
    };

    const handleCopy = (e, itemIndex, item) => {
        e.preventDefault();

        // uuids are used here because these ids are used as keys to differentiate the items while rendering
        item.id = uuidv4();
        item.order = item.order + 1;
        let localTemplate = _.cloneDeep(template);
        localTemplate.items.splice(itemIndex + 1, 0, item);
        arrangeOrders([...localTemplate.items], "TEMPLATE");
        setTemplate(localTemplate);
    };

    const handleDelete = (e, itemIndex) => {
        e.preventDefault();

        let localTemplate = _.cloneDeep(template);
        localTemplate.items = localTemplate.items.map((item) => {
            if ((item.topic_parent_id === localTemplate.items[itemIndex].id) || item.topic_parent_id == itemIndex){
                item.topic_parent_id = null;
            }
            return item;
        })
        const itemsList = template.items.filter(
            (item, index) => index !== itemIndex
        );

        localTemplate.items = itemsList;
        arrangeOrders([...localTemplate.items], "TEMPLATE");
        setTemplate(localTemplate);
    };

    const handleEditorChange = (content, itemIndex) => {
        clearSuccessAndErrorMessages();
        // here we are using a closure inside setTemplate()
        // otherwise inside the SunEditor component we were receiving older value of the meeting state
        // whereas, using closure gives us the current value
        setTemplate((template) => {
            template.items[itemIndex].description = content;
            return template;
        });
    };

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
    }

    function handleTopicChange(e, itemIndex, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        let localTemplate = _.cloneDeep(template);
        if (type === "TOPIC NAME")
            localTemplate.items[itemIndex].name = e.target.value;
        else if (type === "TOPIC DESCRIPTION")
            localTemplate.items[itemIndex].description = e.target.value;
        setTemplate(localTemplate);
    }

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setCreateTemplateError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }
        if (template.items.length === 0) {
            setCreateTemplateError(
                "Please add atleast one item to the template!"
            );
            return false;
        }

        for (let i = 0; i < template.items.length; i++) {
            if (
                template.items[i].type === "TOPIC" &&
                !validateEmptyField(template.items[i].name)
            ) {
                setCreateTemplateError("Please fill all the item name fields");
                return false;
            }
            if (
                template.items[i].type === "AGENDA" &&
                !validateEmptyField(template.items[i].description)
            ) {
                setCreateTemplateError(
                    "Please fill all the text section fields"
                );
                console.log(`template = ${JSON.stringify(template)}`);
                return false;
            }
        }
        return true;
    }

    const handleCreateTemplate = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let newTemplateObject = {};
            newTemplateObject["name"] = name.trim();
            // setting ids of all new template items to null
            template.items.forEach((item) => {
                item.id = null;
            });
            newTemplateObject["add_items"] = template.items;
            try {
                const response = await axios.post(
                    CREATE_TEMPLATE_URL,
                    newTemplateObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );
                if (response.status === 201) {
                    setSuccessMessage("Template created successfully");
                    history.push(TEMPLATES_ROUTE);
                }
            } catch (error) {
                if (error.response) {
                    setCreateTemplateError(error.response.data.error);
                    if (error.response.data.errors)
                        if (error.response.data.errors.name)
                            setNameError(error.response.data.errors.name);
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Create Template</h1>
                        </div>
                        <hr />
                        <Form onSubmit={handleCreateTemplate}>
                            <TextField
                                label={LOCAL_NAME}
                                placeholder={`Enter ${LOCAL_NAME}`}
                                type="text"
                                autofocus={true}
                                value={name ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_NAME)
                                }
                            />
                            {nameError !== null ? (
                                <p className="text-danger">{nameError}</p>
                            ) : null}
                            {/* items */}
                            <div className="mb-2">
                                <AddItemsPopup
                                    handleAddItem={handleAddItem}
                                    addIndex={template.items.length}
                                />
                                {template.items.map((item, itemIndex) => {
                                    return (
                                        <div
                                        className={`item ${'topic_parent_id' in item && item.topic_parent_id !== null ? 'child-item' : ''}`}
                                        id={`itemId-${item.id !== null ? item.id : itemIndex}`}
                                            key={item.id}
                                            ref={(el) =>
                                            (hoverRef.current[itemIndex] =
                                                el)
                                            }
                                            onMouseOver={(e) => {
                                                e.preventDefault();

                                                const mouseY = e.clientY;
                                                const elementY =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect().y;
                                                const elementHeight =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect()
                                                        .height;

                                                setShowPopup(true);
                                                if (
                                                    mouseY - elementY >
                                                    elementHeight / 2
                                                ) {
                                                    if (itemIndex === template.items.length - 1) {
                                                        setIsPopupPosUp(false);
                                                        setShowPopupIndex(itemIndex);
                                                    }
                                                    else
                                                        setShowPopupIndex(itemIndex + 1);
                                                }
                                                else {
                                                    if (itemIndex === template.items.length - 1)
                                                        setIsPopupPosUp(true);
                                                    setShowPopupIndex(itemIndex);
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                e.preventDefault();
                                                setShowPopup(false);
                                                setShowPopupIndex(null);
                                            }}
                                        >
                                            {itemIndex > 0 && ('topic_parent_id' in item === false || item.topic_parent_id === null) ? <hr/> : null}
                                            <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup
                                                && itemIndex === showPopupIndex
                                                && !(itemIndex === template.items.length - 1 && !isPopupPosUp) // This part is only to hide this popup for the last item when the bottom popup of the last item is visible
                                                ? "visible" : "invisible"}`}>
                                                <AddItemsPopup
                                                    handleAddItem={
                                                        handleAddItem
                                                    }
                                                    addIndex={itemIndex}
                                                    addPos="TOP"
                                                    buttonSize="sm"
                                                />
                                            </div>
                                            <div
                                                className="agenda-wrapper border p-2 mt-3"
                                                key={item.id}
                                                draggable={false}
                                                ref={(el) =>
                                                (dragRef.current[
                                                    itemIndex
                                                ] = el)
                                                }
                                                onDragStart={(event) => {
                                                    setReadOnly(true);
                                                    if(itemIndex + 1 >= template.items.length || (template.items[itemIndex+1].topic_parent_id !== item.id && template.items[itemIndex+1].topic_parent_id != itemIndex)){
                                                        setDragParentTopicIndex(itemIndex);
                                                    }
                                                    drag(event, itemIndex);
                                                }}
                                                onDragEnd={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false);
                                                }}
                                                onDrop={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false);
                                                    const localTemplate = drop(
                                                        event,
                                                        itemIndex,
                                                        template,
                                                        setTemplate,
                                                        "TEMPLATE"
                                                    );
                                                    arrangeOrders([...localTemplate.items], 'TEMPLATE');
                                                    setTemplate(localTemplate);
                                                }}
                                                onDragOver={(event) =>
                                                    allowDrop(event)
                                                }
                                            >
                                                <div
                                                    className="drag-wrapper bg-muted"
                                                    // using mouse enter and leave events to set draggable property as true or false
                                                    // otherwise even when someone wants to just select some item text(instead of dragging the item)
                                                    // the item was dragged and this was undesirable
                                                    onMouseEnter={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = true;
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = false;
                                                    }}
                                                >
                                                    <div className="handle">
                                                        ...
                                                    </div>
                                                    <Button
                                                        color="danger float-end"
                                                        onClick={(e) =>
                                                            handleDelete(
                                                                e,
                                                                itemIndex
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        color="warning float-end"
                                                        onClick={(e) =>
                                                            handleCopy(
                                                                e,
                                                                itemIndex,
                                                                // cloneDeep is used here so that any changes made to the item in handleCopy() does not effect the existing item
                                                                _.cloneDeep(
                                                                    item
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Copy
                                                    </Button>
                                                </div>
                                                {item.type === "AGENDA" ? (
                                                    /* agenda */
                                                    <div>
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    /* topic */
                                                    <div>
                                                        <FormGroup
                                                            floating
                                                            className="mt-3"
                                                        >
                                                            <Input
                                                                placeholder="Enter topic name"
                                                                className="topic-name w-50"
                                                                name={`Topic Name ${itemIndex}`}
                                                                id={`Topic Name ${itemIndex}`}
                                                                type="text"
                                                                value={
                                                                    item.name ??
                                                                    ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleTopicChange(
                                                                        e,
                                                                        itemIndex,
                                                                        "TOPIC NAME"
                                                                    )
                                                                }
                                                            />
                                                            <Label
                                                                htmlFor={`Topic Name ${itemIndex}`}
                                                            >
                                                                Topic Name
                                                            </Label>
                                                        </FormGroup>

                                                        {showTopicDescriptionLabel &&
                                                            topicDescriptionLabelIndex ===
                                                            itemIndex ? (
                                                            <Label
                                                                className="topic-description-label"
                                                                htmlFor={`Topic Description ${itemIndex}`}
                                                            >
                                                                Topic
                                                                Description
                                                            </Label>
                                                        ) : null}
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            onFocus={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    itemIndex
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    null
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    false
                                                                );
                                                            }}
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    {(dragParentTopicIndex !== null && dragParentTopicIndex !== itemIndex) && item.topic_parent_id === null ? 
                                                        <Row onDragEnter={() => dragEnterTopic(itemIndex)} onDragExit={dragExitTopic}>
                                                            <Col data-id={itemIndex} className='drag-here-parent'>
                                                                Drag Here To Create Child
                                                            </Col>
                                                        </Row>
                                                        : null    
                                                        }
                                                        <div className="children"></div>
                                                    </div>
                                                )}
                                            </div>
                                            {/* This item popup at the bottom will only appear if this is the last item */}
                                            {itemIndex === template.items.length - 1 ?
                                                <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup && !isPopupPosUp && itemIndex === showPopupIndex ? "visible" : "invisible"}`}>
                                                    <AddItemsPopup
                                                        handleAddItem={
                                                            handleAddItem
                                                        }
                                                        addIndex={itemIndex}
                                                        addPos="BOTTOM"
                                                        buttonSize="sm"
                                                    />
                                                </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            {createTemplateError !== null ? (
                                <p className="text-danger">
                                    {createTemplateError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button
                                color="primary"
                                type="submit"
                                className="mb-5"
                            >
                                Save Template
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateTemplate;
