import { v4 as uuidv4 } from "uuid";

// server urls
export const BASE_SERVER_URL = process.env.REACT_APP_BASE_SERVER_URL;
export const REGISTER_URL = BASE_SERVER_URL + "/api/users";
export const LOGIN_URL = BASE_SERVER_URL + "/api/login";
export const FORGOT_PASSWORD_URL = BASE_SERVER_URL + "/api/forgot_password";
export const PASSWORD_RESET_URL = BASE_SERVER_URL + "/api/reset_password"; // actual endpoint has a token as a query parameter - /api/password_reset?token=sometokenstring
export const GET_ALL_USERS_URL = BASE_SERVER_URL + "/api/users";
export const GET_USER_BY_ID_URL = BASE_SERVER_URL + "/api/users"; // actual endpoint - /api/users/{id}
export const EDIT_USER_BY_ID_URL = BASE_SERVER_URL + "/api/users"; // actual endpoint - /api/users/{id}
export const DELETE_USER_BY_ID_URL = BASE_SERVER_URL + "/api/users"; // actual endpoint - /api/users/{id}
export const ACTIVATE_USER_BY_ID_URL =
    BASE_SERVER_URL + "/api/users/:id/activate";
export const CREATE_CLIENT_URL = BASE_SERVER_URL + "/api/clients";
export const GET_ALL_CLIENTS_URL = BASE_SERVER_URL + "/api/clients";
export const GET_CLIENT_BY_ID_URL = BASE_SERVER_URL + "/api/clients"; // actual endpoint - /api/clients/{id}
export const EDIT_CLIENT_BY_ID_URL = BASE_SERVER_URL + "/api/clients"; // actual endpoint - /api/clients/{id}
export const DELETE_CLIENT_BY_ID_URL = BASE_SERVER_URL + "/api/clients"; // actual endpoint - /api/clients/{id}
export const ACTIVATE_CLIENT_BY_ID_URL =
    BASE_SERVER_URL + "/api/clients/:id/activate";
export const CREATE_GROUP_URL = BASE_SERVER_URL + "/api/groups";
export const GET_ALL_GROUPS_URL = BASE_SERVER_URL + "/api/groups";
export const GET_ALL_PUBLIC_SUBSCRIPTIONS_URL =
    BASE_SERVER_URL + "/api/groups/public_subscriptions";
export const GET_ALL_PUBLIC_GROUPS_URL =
    BASE_SERVER_URL + "/api/groups/public/:client_slug";
export const GET_GROUP_BY_ID_URL = BASE_SERVER_URL + "/api/groups";
export const EDIT_GROUP_BY_ID_URL = BASE_SERVER_URL + "/api/groups";
export const DELETE_GROUP_BY_ID_URL = BASE_SERVER_URL + "/api/groups";
export const ACTIVATE_GROUP_BY_ID_URL =
    BASE_SERVER_URL + "/api/groups/:id/activate";
export const CREATE_TEMPLATE_URL = BASE_SERVER_URL + "/api/templates";
export const GET_ALL_TEMPLATES_URL = BASE_SERVER_URL + "/api/templates";
export const GET_TEMPLATE_BY_ID_URL = BASE_SERVER_URL + "/api/templates";
export const EDIT_TEMPLATE_BY_ID_URL = BASE_SERVER_URL + "/api/templates";
export const DELETE_TEMPLATE_BY_ID_URL = BASE_SERVER_URL + "/api/templates";
export const ACTIVATE_TEMPLATE_BY_ID_URL =
    BASE_SERVER_URL + "/api/templates/:id/activate";
export const CREATE_MEETING_URL = BASE_SERVER_URL + "/api/meetings";
export const GET_ALL_MEETINGS_URL = BASE_SERVER_URL + "/api/meetings";
export const GET_ALL_PUBLIC_MEETINGS_URL =
    BASE_SERVER_URL + "/api/meetings/public/clients/:client_slug";
export const GET_MEETING_BY_ID_URL = BASE_SERVER_URL + "/api/meetings";
export const GET_PUBLIC_MEETING_BY_ID_URL =
    BASE_SERVER_URL + "/api/meetings/public/:id";
export const EDIT_MEETING_BY_ID_URL = BASE_SERVER_URL + "/api/meetings";
export const EDIT_MEETING_MINUTES_BY_ID_URL =
    BASE_SERVER_URL + "/api/meetings/:id/minutes";
export const UPLOAD_MEETING_FILES_URL = BASE_SERVER_URL + "/api/meetings/files";
export const DOWNLOAD_MEETING_FILES_URL =
    BASE_SERVER_URL + "/api/meetings/files/:id";
export const DOWNLOAD_MEETING_PACKETS_URL =
    BASE_SERVER_URL + "/api/meetings/packets/:id";
export const DELETE_MEETING_BY_ID_URL = BASE_SERVER_URL + "/api/meetings";
export const ACTIVATE_MEETING_BY_ID_URL =
    BASE_SERVER_URL + "/api/meetings/:id/activate";
export const GET_MEETING_HISTORY_URL =
    BASE_SERVER_URL + "/api/meetings/:id/history";
export const ADD_PUBLIC_SUBSCRIPTIONS =
    BASE_SERVER_URL + "/api/groups/public/subscribe";
export const CONFIRM_PUBLIC_SUBSCRIPTIONS =
    BASE_SERVER_URL + "/api/groups/public/subscribe/confirm"; // add email and group_id as query parameters
export const PUBLIC_UNSUBSCRIPTIONS =
    BASE_SERVER_URL + "/api/groups/public/unsubscribe"; // add email and group_id as query parameters

// react routes
export const BASE_REACT_ROUTE = process.env.REACT_APP_BASE_REACT_ROUTE;
export const LOGIN_ROUTE = "/login";
export const FORGOT_PASSWORD_ROUTE = "/forgot_password";
export const RESET_PASSWORD_ROUTE = "/password_reset"; // actual endpoint has a token as a query parameter eg:- /password_reset?token=sometokenstring
export const MEETINGS_ROUTE = "/meetings";
export const TEMPLATES_ROUTE = "/templates";
export const CLIENTS_ROUTE = "/clients";
export const GROUPS_ROUTE = "/groups";
export const PUBLIC_SUBSCRIPTIONS_ROUTE = "/public_subscriptions";
export const USERS_ROUTE = "/users";
export const EDIT_USER_ROUTE = "/users/:id/edit"; // actual endpoint has id as a url parameter eg:- /users/33/edit
export const CREATE_USER_ROUTE = "/users/create";
export const EDIT_CLIENT_ROUTE = "/clients/:id/edit"; // actual endpoint has id as a url parameter eg:- /clients/33/edit
export const CREATE_CLIENT_ROUTE = "/clients/create";
export const CREATE_GROUP_ROUTE = "/groups/create";
export const EDIT_GROUP_ROUTE = "/groups/:id/edit"; // actual endpoint has id as a url parameter eg:- /users/33/edit
export const CREATE_TEMPLATE_ROUTE = "/templates/create";
export const VIEW_TEMPLATE_ROUTE = "/templates/:id/view";
export const EDIT_TEMPLATE_ROUTE = "/templates/:id/edit";
export const CREATE_MEETING_ROUTE = "/meetings/create";
export const VIEW_MEETING_ROUTE = "/meetings/:id/view/";
export const VIEW_PUBLIC_MEETING_ROUTE = "/meetings/:id/view/public";
export const EDIT_MEETING_ROUTE = "/meetings/:id/edit";
export const PUBLIC_MEETING_ROUTE = "/:client_slug"; // domain.com/client-slug/ - client-slug like "greenville-mi" - meeting list with also a link to subscribe page
export const SUBSCRIPTION_ROUTE = "/:client_slug/subscribe"; // domain.com/client-slug/subscribe - Email entry with choice of subscribable groups
export const UNSUBSCRIPTION_ROUTE = "/unsubscribe/:email/:group_id";
export const CONFIRM_PUBLIC_SUBSCRIPTION_ROUTE =
    "/confirm_public_subscription/:email/:group_id";

// local storage item names
export const LS_SESSION_JWT = "SESSION_JWT";
export const LS_CURRENT_USER = "CURRENT_USER";

export const COPYRIGHT_TEXT = `© 1995-${new Date().getFullYear()} Revize. All Rights Reserved`;

export const USER_ROLES_ENUM = ["USER", "ADMIN", "SUPERUSER"];
export const ITEMS_PER_PAGE_LIST = [10, 25, 50, 100];
export const MEETING_SORT_BY_ENUM = ["DATE", "UPDATED_AT", "CREATED_AT"];
export const SORT_ORDER_ENUM = ["DESC", "ASC"];
export const TIME_PERIOD_FOR_MEETING_UPDATE_CHECK = 1000 * 60 * 5; // 5 minutes

export const FAKE_UUID_IDENTIFIER = "fake-uuid";

export const DEFAULT_TEMPLATE_ITEMS = [
    {
        // uuids are used here because these ids are used as keys to differentiate the items while rendering
        // "fake-uuid:" is added to the start to differentiate them from actual uuids generated from the db while uploads files, etc..
        id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
        description: null,
        order: 1,
        is_active: 1,
        type: "AGENDA",
        topic_parent_id: null
    },
    {
        id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
        name: null,
        description: null,
        order: 2,
        is_active: 1,
        type: "TOPIC",
        topic_parent_id: null
    },
];

export const DEFAULT_MEETING_ITEMS = [
    {
        // uuids are used here because these ids are used as keys to differentiate the items while rendering
        // "fake-uuid:" is added to the start to differentiate them from actual uuids generated from the db while uploads files, etc..
        id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
        description: null,
        order: 1,
        is_active: 1,
        type: "AGENDA",
        topic_parent_id: null
    },
    {
        id: `${FAKE_UUID_IDENTIFIER}:${uuidv4()}`,
        name: null,
        description: null,
        order: 2,
        is_active: 1,
        minute: null,
        is_private_file: false,
        is_private_topic: false,
        type: "TOPIC",
        topic_parent_id: null
    },
];

export const SUN_EDITOR_DEFAULT_STYLE = "font-family: Arial; height: auto";

export const SUN_EDITOR_BUTTONS_LIST = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "italic", "underline", "strike", "subscript", "superscript"],
    [
        "fontColor",
        "hiliteColor",
        "align",
        "outdent",
        "indent",
        "list",
        "removeFormat",
        "table",
        "link",
    ],
];
