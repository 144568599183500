import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    EDIT_TEMPLATE_BY_ID_URL,
    GET_TEMPLATE_BY_ID_URL,
    LOGIN_ROUTE,
    SUN_EDITOR_BUTTONS_LIST,
    SUN_EDITOR_DEFAULT_STYLE,
} from "../../constants";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/editTemplate.css";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { allowDrop, drag, dragEnterTopic, dragExitTopic, drop } from "../utils/dragNdrop";
import _ from "lodash";
import { arrangeOrders } from "../utils/arrangeOrders";
import { useRef } from "react";
import { capitalize } from "../utils/stringUtils";
import AddItemsPopup from "../widgets/addItemsPopup";
import updateOrders from "../utils/updateOrders";

const EditTemplate = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const { id } = useParams();

    const dragRef = useRef([]);
    const hoverRef = useRef([]);
    const [dragParentTopicIndex, setDragParentTopicIndex] = useState(null);

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [editTemplateError, setEditTemplateError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [content, setContent] = useState(null);

    const LOCAL_NAME = "Name";
    const [remove_items, setRemoveItems] = useState([]);
    const [update_items_id_type_list, setUpdateItemsIdTypeList] = useState([]);
    let update_items = [];

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupIndex, setShowPopupIndex] = useState(null);
    const [isPopupPosUp, setIsPopupPosUp] = useState(true); // is the popup position on the top of the item or not (this is only used for the last item)
    const [readOnly, setReadOnly] = useState(false);
    const [showTopicDescriptionLabel, setShowTopicDescriptionLabel] =
        useState(false);
    const [topicDescriptionLabelIndex, setTopicDescriptionLabelIndex] =
        useState(null);

    const [originalTemplate, setOriginalTemplate] = useState(null);
    const [template, setTemplate] = useState({
        name: null,
        items: [

        ],
    });

    const fetchTemplate = async () => {
        const response = await axios.get(GET_TEMPLATE_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setName(response.data.template.name);
        const localTemplate = response.data.template;
        localTemplate.items = localTemplate.items.map((item,itemIndex)=>{
            // checking if parent id is set but an index rather then an id
            if (item.topic_parent_id !== null && item.topic_parent_id.length < 36){
                item.topic_parent_id = localTemplate.items[itemIndex-1].id;
                setUpdateItemsIdTypeList([
                    ...update_items_id_type_list,
                    {
                        id: item.id,
                        type: item.type
                    }
                ]);
            }

            return item;
        })
        setTemplate(localTemplate);
        setOriginalTemplate(response.data.template);
    };

    useEffect(() => {
        fetchTemplate();
    }, []);

    const handleAddItem = (e, type, addIndex, addPos) => {
        e.preventDefault();

        if (addPos === "BOTTOM") ++addIndex;

        let localTemplate = _.cloneDeep(template);
        const itemsLength = localTemplate.items.length;
        if (type === "AGENDA") {
            localTemplate.items.splice(addIndex, 0, {
                id: null,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                topic_parent_id: null,
                type: "AGENDA",
            });
        } else if (type === "TOPIC") {
            localTemplate.items.splice(addIndex, 0, {
                id: null,
                name: null,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                topic_parent_id: null,
                type: "TOPIC",
                child_numbering: null
            });
        }

        arrangeOrders([...localTemplate.items], "TEMPLATE");
        updateOrders(template, localTemplate.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setTemplate(localTemplate);
    };

    const handleCopy = (e, itemIndex, item) => {
        e.preventDefault();

        const originalItemOrder = item.order;
        let localTemplate = _.cloneDeep(template);
        localTemplate.items.splice(itemIndex + 1, 0, item);
        localTemplate.items[itemIndex + 1].id = null; //uuidv4();
        localTemplate.items[itemIndex + 1].order = originalItemOrder + 1;

        arrangeOrders([...localTemplate.items], "TEMPLATE");
        updateOrders(template, localTemplate.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setTemplate(localTemplate);
    };

    const handleDelete = (e, itemIndex) => {
        e.preventDefault();

        let localTemplate = _.cloneDeep(template);
        localTemplate.items = localTemplate.items.map((item) => {
            if ((item.topic_parent_id === localTemplate.items[itemIndex].id) || item.topic_parent_id == itemIndex){
                item.topic_parent_id = null;
            }
            return item;
        })    
        localTemplate.items = localTemplate.items.filter(
            (item, index) => index !== itemIndex
        );
        if (template.items[itemIndex].id !== null) {
            setRemoveItems([
                ...remove_items,
                {
                    id: template.items[itemIndex].id,
                    type: template.items[itemIndex].type,
                },
            ]);
            if (
                !checkIfItemExistsInUpdateList(
                    update_items_id_type_list,
                    template,
                    itemIndex
                )
            ) {
                setUpdateItemsIdTypeList(
                    update_items_id_type_list.filter(
                        (item_id_type) =>
                            item_id_type.id !== template.items[itemIndex].id &&
                            item_id_type.type !== template.items[itemIndex].type
                    )
                );
            }
        }
        arrangeOrders([...localTemplate.items], "TEMPLATE");
        updateOrders(template, localTemplate.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setTemplate(localTemplate);
    };

    const handleEditorChange = (content, itemIndex) => {
        // here we are using a closure inside setTemplate()
        // otherwise inside the SunEditor component we were receiving older value of the meeting state
        // whereas, using closure gives us the current value
        setTemplate((template) => {
            template.items[itemIndex].description = content;
            // check if updated item is an existing item or newly added item
            if (template.items[itemIndex].id !== null)
                setUpdateItemsIdTypeList((update_items_id_type_list)=> {
                    if (
                        !checkIfItemExistsInUpdateList(
                            update_items_id_type_list,
                            template,
                            itemIndex
                        )
                    ){
                        update_items_id_type_list.push(
                            {
                                id: template.items[itemIndex].id,
                                type: template.items[itemIndex].type,
                            },
                        )
                    }
                    return update_items_id_type_list
                });
            return template;
        });
    };

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
    }

    const checkIfItemExistsInUpdateList = (array, template, itemIndex) => {
        if (array.length === 0) return false;
        return (
            array.find(
                (element) =>
                    element["id"] === template.items[itemIndex].id &&
                    element["type"] === template.items[itemIndex].type
            ) !== undefined
        );
    };

    function handleTopicChange(e, itemIndex, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        let localTemplate = _.cloneDeep(template);
        if (type === "TOPIC NAME")
            localTemplate.items[itemIndex].name = e.target.value;
        else if (type === "TOPIC DESCRIPTION")
            localTemplate.items[itemIndex].description = e.target.value;
        else if (type === "CHILD NUMBERING")
            localTemplate.items[itemIndex].child_numbering = e.target.value;
        setTemplate(localTemplate);

        // check if updated item is an existing item or newly added item
        if (localTemplate.items[itemIndex].id !== null)
            if (
                !checkIfItemExistsInUpdateList(
                    update_items_id_type_list,
                    localTemplate,
                    itemIndex
                )
            )
                setUpdateItemsIdTypeList([
                    ...update_items_id_type_list,
                    {
                        id: template.items[itemIndex].id,
                        type: template.items[itemIndex].type,
                    },
                ]);
    }

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setEditTemplateError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }

        for (let i = 0; i < template.items.length; i++) {
            if (
                template.items[i].type === "TOPIC" &&
                !validateEmptyField(template.items[i].name)
            ) {
                setEditTemplateError("Please fill all the item name fields");
                return false;
            }
            if (
                template.items[i].type === "AGENDA" &&
                !validateEmptyField(template.items[i].description)
            ) {
                setEditTemplateError("Please fill all the text section fields");
                return false;
            }
        }
        return true;
    }

    const checkForUpdateItems = () => {
        update_items_id_type_list.forEach((updateItemIdType) => {
            const originalItem = originalTemplate.items.filter(
                (item) =>
                    item.id === updateItemIdType.id &&
                    item.type === updateItemIdType.type
            )[0];
            const updatedItem = template.items.filter(
                (item) =>
                    item.id === updateItemIdType.id &&
                    item.type === updateItemIdType.type
            )[0];

            if (originalItem.order === updatedItem.order) {
                delete updatedItem["order"];
            }
            update_items.push(updatedItem);
        });
    };

    const handleEditTemplate = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let updatedTemplateObject = {};
            if (name !== template.name)
                updatedTemplateObject["name"] = name.trim();
            let add_items = [];
            template.items.forEach((item) => {
                if (item.id === null)
                    add_items = [...add_items, _.cloneDeep(item)];
            });
            console.log(`FINAL update list = ${JSON.stringify(update_items_id_type_list)}`);
            checkForUpdateItems();
            if (add_items.length !== 0)
                updatedTemplateObject["add_items"] = add_items;
            if (remove_items.length !== 0)
                updatedTemplateObject["remove_items"] = remove_items;
            if (update_items.length !== 0)
                updatedTemplateObject["update_items"] = update_items;
            try {
                const response = await axios.patch(
                    EDIT_TEMPLATE_BY_ID_URL + `/${id}`,
                    updatedTemplateObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );
                if (response.status === 200) {
                    setSuccessMessage("Template updated successfully");
                    setTemplate(response.data.template);
                    setOriginalTemplate(response.data.template);

                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            } catch (error) {
                if (error.response) {
                    setEditTemplateError(error.response.data.error);
                    if (error.response.data.errors)
                        if (error.response.data.errors.name)
                            setNameError(error.response.data.errors.name);
                }
            }
            // reset the remove_items, update_items and update_items_id_type_list
            setRemoveItems([]);
            update_items = [];
            setUpdateItemsIdTypeList([]);
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Edit Template</h1>
                        </div>
                        <hr />
                        {successMessage !== null ? (
                            <Alert
                                color="success"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setSuccessMessage(null);
                                }}
                            >
                                {successMessage}
                            </Alert>
                        ) : null}
                        {editTemplateError !== null ? (
                            <Alert
                                color="danger"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setEditTemplateError(null);
                                }}
                            >
                                {editTemplateError}
                            </Alert>
                        ) : null}
                        <Form onSubmit={handleEditTemplate}>
                            <TextField
                                label={LOCAL_NAME}
                                placeholder={`Enter ${LOCAL_NAME}`}
                                type="text"
                                autofocus={true}
                                value={name ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_NAME)
                                }
                            />
                            {nameError !== null ? (
                                <p className="text-danger">{nameError}</p>
                            ) : null}
                            {/* agendas */}
                            <div className="mb-2">
                                <AddItemsPopup
                                    handleAddItem={handleAddItem}
                                    addIndex={template.items.length}
                                />
                                {template.items.map((item, itemIndex) => {
                                    return (
                                        <div
                                        className={`item ${'topic_parent_id' in item && item.topic_parent_id !== null ? 'child-item' : ''}`}
                                            id={`itemId-${item.id !== null ? item.id : itemIndex}`}
                                            key={item.id}
                                            ref={(el) =>
                                            (hoverRef.current[itemIndex] =
                                                el)
                                            }
                                            onMouseOver={(e) => {
                                                e.preventDefault();

                                                const mouseY = e.clientY;
                                                const elementY =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect().y;
                                                const elementHeight =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect()
                                                        .height;
                                                setShowPopup(true);
                                                if (
                                                    mouseY - elementY >
                                                    elementHeight / 2
                                                ) {
                                                    if (itemIndex === template.items.length - 1) {
                                                        setIsPopupPosUp(false);
                                                        setShowPopupIndex(itemIndex);
                                                    }
                                                    else
                                                        setShowPopupIndex(itemIndex + 1);
                                                }
                                                else {
                                                    if (itemIndex === template.items.length - 1)
                                                        setIsPopupPosUp(true);
                                                    setShowPopupIndex(itemIndex);
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                e.preventDefault();
                                                setShowPopup(false);
                                                setShowPopupIndex(null);
                                            }}
                                        >
                                            {itemIndex > 0 && ('topic_parent_id' in item === false || item.topic_parent_id === null) ? <hr/> : null}
                                            <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup
                                                && itemIndex === showPopupIndex
                                                && !(itemIndex === template.items.length - 1 && !isPopupPosUp) // This part is only to hide this popup for the last item when the bottom popup of the last item is visible
                                                ? "visible" : "invisible"}`}>
                                                <AddItemsPopup
                                                    handleAddItem={
                                                        handleAddItem
                                                    }
                                                    addIndex={itemIndex}
                                                    addPos="TOP"
                                                    buttonSize="sm"
                                                />
                                            </div>
                                            <div
                                                className="agenda-wrapper border p-2 mt-3"
                                                key={item.id}
                                                draggable={false}
                                                ref={(el) =>
                                                (dragRef.current[
                                                    itemIndex
                                                ] = el)
                                                }
                                                onDragStart={(event) => {
                                                    setReadOnly(true);
                                                    if(itemIndex + 1 >= template.items.length || (template.items[itemIndex+1].topic_parent_id !== item.id && template.items[itemIndex+1].topic_parent_id != itemIndex)){
                                                        setDragParentTopicIndex(itemIndex);
                                                    }
                                                    drag(event, itemIndex);
                                                }}
                                                onDragEnd={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false);
                                                }}
                                                onDrop={(event) => {
                                                    setDragParentTopicIndex(null);
                                                    setReadOnly(false);
                                                    const localTemplate = drop(
                                                        event,
                                                        itemIndex,
                                                        template
                                                    );
                                                    arrangeOrders([...localTemplate.items], "TEMPLATE");
                                                    updateOrders(template, localTemplate.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
                                                    setTemplate(localTemplate);
                                                }}
                                                onDragOver={(event) =>
                                                    allowDrop(event)
                                                }
                                            >
                                                <div
                                                    className="drag-wrapper bg-muted"
                                                    // using mouse enter and leave events to set draggable property as true or false
                                                    // otherwise even when someone wants to just select some item text(instead of dragging the item)
                                                    // the item was dragged and this was undesirable
                                                    onMouseEnter={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = true;
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = false;
                                                    }}
                                                >
                                                    <div className="handle">
                                                        ...
                                                    </div>
                                                    <Button
                                                        color="danger float-end"
                                                        onClick={(e) =>
                                                            handleDelete(
                                                                e,
                                                                itemIndex
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        color="warning float-end"
                                                        onClick={(e) =>
                                                            handleCopy(
                                                                e,
                                                                itemIndex,
                                                                // cloneDeep is used here so that any changes made to the item in handleCopy() does not effect the existing item
                                                                _.cloneDeep(
                                                                    item
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Copy
                                                    </Button>
                                                </div>
                                                {item.type === "AGENDA" ? (
                                                    /* agenda */
                                                    <div>
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description ??
                                                                ""
                                                            }
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    /* topic */
                                                    <div>
                                                        <div className="d-flex">
                                                        <FormGroup
                                                            floating
                                                            className="mt-3 me-3 flex-grow-1"
                                                        >
                                                            <Input
                                                                placeholder="Enter topic name"
                                                                className="topic-name w-100"
                                                                name={`Topic Name ${itemIndex}`}
                                                                id={`Topic Name ${itemIndex}`}
                                                                type="text"
                                                                value={
                                                                    item.name ??
                                                                    ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleTopicChange(
                                                                        e,
                                                                        itemIndex,
                                                                        "TOPIC NAME"
                                                                    )
                                                                }
                                                            />
                                                            <Label
                                                                htmlFor={`Topic Name ${itemIndex}`}
                                                            >
                                                                Topic Name
                                                            </Label>
                                                        </FormGroup>
                                                        {itemIndex + 1 < template.items.length && template.items[itemIndex + 1].topic_parent_id !== null ? 
                                                            <FormGroup className='mt-3 me-3 flex-grow-1' floating>
                                                            <Input
                                                                id={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}
                                                                name={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}
                                                                type="select"
                                                                className="mb-2"
                                                                value={item.child_numbering ?? "None"}
                                                                onChange={(e) =>
                                                                    handleTopicChange(
                                                                        e,
                                                                        itemIndex,
                                                                        'CHILD NUMBERING'
                                                                    )
                                                                }
                                                            >
                                                                {["None", "A", "1", "I"].map((element) => {
                                                                    return (
                                                                        <option key={element}>{element}</option>
                                                                    );
                                                                })}
                                                            </Input>
                                                            <Label htmlFor={`${itemIndex}-LOCAL_TOPIC_NUMBERING`}>
                                                                Sub-Item Numbering
                                                            </Label>
                                                            </FormGroup>
                                                            : null
                                                            }

                                                        </div>

                                                        {showTopicDescriptionLabel &&
                                                            itemIndex ===
                                                            topicDescriptionLabelIndex ? (
                                                            <Label
                                                                className="topic-description-label"
                                                                htmlFor={`Topic Description ${itemIndex}`}
                                                            >
                                                                Topic
                                                                Description
                                                            </Label>
                                                        ) : null}
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description ??
                                                                ""
                                                            }
                                                            showToolbar={true}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            onFocus={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    itemIndex
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    null
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    false
                                                                );
                                                            }}
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    {(dragParentTopicIndex !== null && dragParentTopicIndex !== itemIndex) && item.topic_parent_id === null ? 
                                                        <Row onDragEnter={() => dragEnterTopic(itemIndex)} onDragExit={dragExitTopic}>
                                                            <Col data-id={itemIndex} className='drag-here-parent'>
                                                                Drag Here To Create Child
                                                            </Col>
                                                        </Row>
                                                        : null    
                                                        }
                                                        <div className="children"></div>
                                                    </div>
                                                )}
                                            </div>
                                            {/* This item popup at the bottom will only appear if this is the last item */}
                                            {itemIndex === template.items.length - 1 ?
                                                <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup && !isPopupPosUp && itemIndex === showPopupIndex ? "visible" : "invisible"}`}>
                                                    <AddItemsPopup
                                                        handleAddItem={
                                                            handleAddItem
                                                        }
                                                        addIndex={itemIndex}
                                                        addPos="BOTTOM"
                                                        buttonSize="sm"
                                                    />
                                                </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                color="primary"
                                type="submit"
                                className="mb-5"
                            >
                                Save Template
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditTemplate;
