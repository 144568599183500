import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import {
    GET_TEMPLATE_BY_ID_URL,
    LOGIN_ROUTE,
    SUN_EDITOR_BUTTONS_LIST,
} from "../../constants";
import ProjectNavbar from "../widgets/projectNavbar";
import "./../../styles/createTemplate.css";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const ViewTemplate = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const { id } = useParams();

    const [template, setTemplate] = useState({
        name: null,
        items: [
            {
                description: null,
                order: null,
                is_active: null,
                type: null,
            },
        ],
    });

    const fetchTemplate = async () => {
        const response = await axios.get(GET_TEMPLATE_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setTemplate(response.data.template);
    };

    useEffect(() => {
        fetchTemplate();
    }, []);

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <h1 className="d-inline">View Template</h1>
                        <hr />
                        <p className="fw-bold m-0">Name</p>
                        <p>{template.name}</p>
                        <div className="mb-2">
                            {template.items.map((item, itemIndex) => {
                                return item.type === "AGENDA" ? (
                                    <ViewTemplateAgenda 
                                        item={item}
                                    />
                                ) : (
                                    <ViewTemplateTopic 
                                        item={item}
                                        itemIndex={itemIndex}
                                        template={template}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const ViewTemplateTopic = ({item, itemIndex,template}) => {
    const children = [];
    let childIndex = itemIndex + 1;
    while (childIndex < template.items.length && (template.items[childIndex].topic_parent_id === item.id || template.items[childIndex].topic_parent_id == itemIndex)){
        children.push(template.items[childIndex]);
        childIndex++;
    }
    return(
        <div
            key={`Topic ${item.id}`}
            className="p-2 mt-2 mb-2 view-suneditor"
        >
            <p className="fw-bold m-0">Topic Name</p>
            <p>{item.name}</p>
            <p className="fw-bold m-0">
                Description
            </p>
            <SunEditor
                defaultValue={item.description}
                showToolbar={true}
                setDefaultStyle="height: auto"
                setOptions={{
                    buttonList:
                        SUN_EDITOR_BUTTONS_LIST,
                }}
                disable={true}
            />
            {children.length > 0 ? <ol type={item.child_numbering}>
                            
            {children.map((child,childIndex) => {
                if(child.type === 'TOPIC'){
                    return (
                        <li className="li-marker-bold">
                            <ViewTemplateTopic 
                                key={child.id}
                                item={child}
                                itemIndex={childIndex}
                                template={template}
                            />
                        </li>
                    )
                }else{
                    return (
                    <li className="li-marker-bold">
                        <ViewTemplateAgenda 
                            key={child.id}
                            item={child} />
                    </li>
                    )
                }
            })}    
        
        </ol> : null}
        </div>
    )
}

const ViewTemplateAgenda = ({item}) => {
    return(
        <div
            key={`Agenda ${item.id}`}
            className="p-2 mt-2 mb-2 view-suneditor"
        >
            <p className="fw-bold m-0">Text Section</p>
            <SunEditor
                defaultValue={item.description}
                showToolbar={true}
                setDefaultStyle="height: auto"
                setOptions={{
                    buttonList: 
                        SUN_EDITOR_BUTTONS_LIST,
                }}
                disable={true}
            />
        </div>
    )
}

export default ViewTemplate;
